import React from "react";
import styled from "styled-components";

export const StyledMenu = styled.nav`
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  grid-row-gap: 1vh;
  background: ${({ theme }) => theme.primaryLight};
  width: 100vw;
  min-width: 100vw;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  text-align: left;
  padding: 2rem;
  top: 12vh;
  position: absolute;
  left: 0;
  transition: transform 0.3s ease-in-out;
  background-color: #fff;
  height: 75vh;
  padding-top: 0vh;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
  }
  overflow-x: scroll !important;

  div {
    display: flex;
    align-items: center;
    max-height: 10vh;
  }

  a {
    font-size: 1.8rem;
    line-height: 2rem;
    max-width: 80vw;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: normal;
    letter-spacing: 0.5rem;
    color: ${({ theme }) => theme.primaryDark};
    text-decoration: none;
    color: black;

    @media (max-width: ${({ theme }) => theme.mobile}) {
      font-size: 1.5rem;
      text-align: center;
    }
  }
`;

const Menu = ({ children, open, ...props }) => {
  const isHidden = !!open;
  const tabIndex = isHidden ? 0 : -1;

  const visibleItems = [
    ...children,
    { name: "Instagram", route: "ig-feed" },
    { name: "Contact Us", route: "contact-us" },
  ];

  return (
    <StyledMenu open={open} aria-hidden={!isHidden} {...props}>
      {visibleItems.map((child, ind) => (
        <div key={child.name}>
          <a key={ind} href={child.route} tabIndex={tabIndex}>
            <span aria-hidden="true" />
            {child.name}
          </a>
        </div>
      ))}
    </StyledMenu>
  );
};

export default Menu;
