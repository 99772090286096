import styled from "styled-components";
import { Card, Box, Spinner, Button } from "grommet";

export const Wrapper = styled.div`
  width: 100%;
  height: 85vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  box-sizing: border-box;
`;

export const MobileWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 85vh;
  flex-direction: column;
  overflow: scroll;
  margin-top: 3vh;
`;

export const ListingImage = styled.img`
  max-width: 100%;
  cursor: pointer;
  height: 100%;
  width: 100%;
`;

export const ListingData = styled(Card)`
  max-width: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2em;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const MobileListingData = styled.div`
  max-width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ListingWrapper = styled(Card)`
  overflow: scroll;
`;

export const SizeWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
`;

export const Loading = styled(Spinner)`
  width: 300px;
  height: 300px;
  margin: 0 auto;
  border-top-color: #bd2e2e;
  border-top-width: 1em;
  position: absolute;
  top: 33vh;
  left: 0;
  right: 0;
`;

export const ListingList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-height: 90vh;
  max-width: 100vw;
  overflow-x: scroll;
`;

export const MobileListingList = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  max-height: 75vh;
  max-width: 100vw;
  overflow-x: scroll;
  margin-top: 3vh;
`;

export const ListingListItem = styled.img`
  cursor: pointer;
  width: 100%;
  object-fit: cover;
  position: absolute;
  height: 100%;
`;

export const ListingListItemContainer = styled.div`
  cursor: pointer;
  max-width: 33.33333vw;
  position: relative;
  height: 30vh;
`;

export const MobileListingListItemContainer = styled.div`
  cursor: pointer;
  max-width: 100%;
  position: relative;
  height: 30vh;
`;

export const ListingListItemOverlay = styled.div`
  cursor: pointer;
  aspect-ratio: 4 / 3;
  width: 100%;
  z-index: 99999;
  position: absolute;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  font-size: 2em;
  color: #fff;
  ${"" /* font-weight: bold; */}
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;
  > div {
    display: none;
  }
  &:hover {
    > div {
      display: block;
      margin-top: 16px;
    }
    background-color: rgba(0, 0, 0, 0.65);
    animation: 0.4s linear;
    transition: background-color 0.01s linear;
  }
`;

export const CondoImage = styled.img`
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const CondoContainer = styled.div`
  cursor: pointer;
  max-width: 33.33333vw;
  position: relative;
  height: 30vh;
`;

export const MobileCondoContainer = styled.div`
  cursor: pointer;
  max-width: 100%;
  position: relative;
  height: 30vh;
`;

export const CondoOverlay = styled.div`
  cursor: pointer;
  width: 100%;
  z-index: 99999;
  position: absolute;
  height: 100%;
  font-size: 2em;
  color: #fff;
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;
  > div {
    display: none;
  }
  background-color: rgba(0, 0, 0, 0);
  &:hover {
    background-color: rgba(0, 0, 0, 0.75);
    animation: 0.8s linear;
    transition: background-color 0.2s linear;

    > div {
      display: block;
      margin-top: 16px;
    }
  }
`;

export const CondosWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 100vw;
`;

export const MobileCondosWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  max-width: 100vw;
`;

export const MobileCondoWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  max-width: 100vw;
  margin-top: 3vh;
  height: 85vh;
  overflow: scroll;
`;

export const Back = styled(Button)`
  position: absolute;
  top: ${({ isMobile }) => (isMobile ? "2vh" : "3.5vh")};
  left: 5vw;
  width: ${({ isMobile }) => (isMobile ? "10vw" : "5vw")};
  height: ${({ isMobile }) => (isMobile ? "6%" : null)};
  font-size: ${({ isMobile }) => (isMobile ? "3em" : null)};
  padding-bottom: ${({ isMobile }) => (isMobile ? "10px" : null)};
  border: 1px solid #ddd;
  z-index: 99999;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;
