import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Box, Heading, Chart } from "grommet";
import styled from "styled-components";

import { getLeads } from "./firebase/actions";

const Wrapper = styled(Box)`
  margin: 1em;
  padding: 2em;
  background-color: #eee;
  border-radius: 1em;
`;

const CardWrapper = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

const RemindersHeading = styled(Heading)`
  margin-bottom: 0.5em;
`;

const LeadHeader = styled(CardHeader)`
  padding-bottom: 0px;
`;

const LeadHeading = styled(Heading)`
  margin-top: 0px;
`;

const LeadSubheading = styled(Heading)`
  font-weight: 100;
  margin-top: -10px;
`;

const Admin = () => {
  console.log("This is the admin view");
  const [leads, setLeads] = useState([]);
  useEffect(async () => {
    const snapshot = await getLeads();
    const allLeads = snapshot.docs.map((doc) => doc.data());
    setLeads(allLeads);
  }, []);

  const LeadCard = ({ firstName, lastName, email, message, subject }) => (
    <Card height="medium" width="medium" background="light-1">
      <LeadHeader pad="medium" background="light-1">
        <Box>
          <LeadHeading level={3} style={{ textTransform: "capitalize" }}>
            {firstName} {lastName}
          </LeadHeading>
          <LeadSubheading level={5} size="small">
            {email}
          </LeadSubheading>
        </Box>
      </LeadHeader>
      <CardBody pad="medium">
        <Box>
          <LeadHeading level={4}>{subject}</LeadHeading>

          {message}
        </Box>
      </CardBody>
    </Card>
  );

  const AnalyticsChart = (props) => (
    <Chart
      {...props}
      bounds={[
        [0, 7],
        [0, 100],
      ]}
      values={[
        { value: [7, 100], label: "one hundred" },
        { value: [6, 70], label: "seventy" },
        { value: [5, 60], label: "sixty" },
        { value: [4, 80], label: "eighty" },
        { value: [3, 40], label: "forty" },
        { value: [2, 12], label: "zero" },
        { value: [1, 30], label: "thirty" },
        { value: [0, 60], label: "sixty" },
      ]}
      aria-label="chart"
    />
  );

  return (
    <>
      <Wrapper>
        <RemindersHeading margin="none">New Leads</RemindersHeading>
        <CardWrapper>
          {leads.length &&
            leads.length !== 0 &&
            leads.map((lead) => <LeadCard {...lead} />)}
        </CardWrapper>
      </Wrapper>
      <Wrapper>
        <RemindersHeading margin="none">Daily Stats</RemindersHeading>
        <Heading level={3} style={{ marginTop: "-10px", fontWeight: 100 }}>
          {new Date().toLocaleString()}{" "}
        </Heading>

        <CardWrapper>
          {/* New Leads Per day */}
          <Card height="medium" width="medium" background="light-1">
            <CardHeader pad="medium">
              <Heading level={3}>New Leads / Day</Heading>
            </CardHeader>
            <CardBody pad="medium">
              <AnalyticsChart animate type="point" />
            </CardBody>
          </Card>

          {/* Number of visits per day */}
          <Card height="medium" width="medium" background="light-1">
            <CardHeader pad="medium">
              <Heading level={3}>No. of visits/day</Heading>
            </CardHeader>
            <CardBody pad="medium">
              <AnalyticsChart animate />
            </CardBody>
          </Card>

          {/* Average time spent on site */}
          <Card height="medium" width="medium" background="light-1">
            <CardHeader pad="medium">
              <Heading level={3}>Avg Time on Site Before Bounce</Heading>
            </CardHeader>
            <CardBody pad="medium">
              <AnalyticsChart animate type="area" pattern="line" />
            </CardBody>
          </Card>
        </CardWrapper>
      </Wrapper>
    </>
  );
};

export default Admin;
