import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCOf8c8oo-lonPaE3Umh_94a5oaYXI64wE",
  authDomain: "website-2021-1221f.firebaseapp.com",
  projectId: "website-2021-1221f",
  storageBucket: "website-2021-1221f.appspot.com",
  messagingSenderId: "893214818194",
  appId: "1:893214818194:web:1fa313eafe4ec14d210b4b",
  measurementId: "G-EBHD6NRHCX",
};

const fb = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export { fb, db };
