const _temp0 = require("./1521_01.webp");
const _temp1 = require("./1521_022.webp");
const _temp2 = require("./1521_04.webp");
const _temp3 = require("./1521_05.webp");
const _temp4 = require("./1521_06.webp");
const _temp5 = require("./main.webp");
module.exports = {
  "1521_01": _temp0,
  "1521_022": _temp1,
  "1521_04": _temp2,
  "1521_05": _temp3,
  "1521_06": _temp4,
  "main": _temp5
}