import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import axios from "axios";
import {
  Loading,
  ListingList,
  ListingListItem,
  ListingListItemOverlay,
  ListingListItemContainer,
  MobileListingList,
  MobileListingListItemContainer,
} from "./common";
import Listing from "./listing";

const IDX_WORKER = "https://idx-worker.ryan1837.workers.dev";
const IDX_WORKER_DEV = "http://127.0.0.1:8787";
const IMAGE_WORKER = "https://image-handler.ryan1837.workers.dev";

const Featured = ({ isMobile }) => {
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resolved, setResolved] = useState(null);
  const [currentListing, setCurrentListing] = useState(null);
  const { pathname } = useLocation;

  useEffect(async () => {
    setLoading(true);

    const res = await axios(`${IDX_WORKER}/featured`);
    const { data } = res;
    setResolved(data.length);

    data.forEach((listing) => {
      const imageUrls = Object.values(listing.image).map((i) => i.url);
      const images = [];
      imageUrls
        .slice(0, 8)
        .forEach(async (url) =>
          images.push(axios(`${IMAGE_WORKER}/corsproxy/${url}`))
        );

      Promise.allSettled(images).then((d) => {
        const uris = d.map((v) => v.value.data);
        setListings((r) => [...r, { ...listing, images: uris }]);
      });
    });
  }, []);

  useEffect(() => {
    if (listings.length === resolved) {
      setLoading(false);
    }
  }, [listings]);

  return !loading ? (
    <>
      {currentListing ? (
        <Listing
          isMobile={isMobile}
          listing={currentListing}
          clearListing={() => setCurrentListing(null)}
        />
      ) : !isMobile ? (
        <ListingList currentListing={pathname !== "/featured"}>
          {listings.map((listing) => {
            const src = `data:image/png;base64,${listing.images[0]}`;
            return (
              <ListingListItemContainer
                onClick={() => setCurrentListing(listing)}
              >
                <ListingListItemOverlay>
                  <div>{listing.address}</div>
                  <div>{listing.listingPrice}</div>
                </ListingListItemOverlay>
                <ListingListItem src={src} key={listing.listingID} />
              </ListingListItemContainer>
            );
          })}
        </ListingList>
      ) : (
        <MobileListingList currentListing={pathname !== "/featured"}>
          {listings.map((listing) => {
            const src = `data:image/png;base64,${listing.images[0]}`;
            return (
              <MobileListingListItemContainer
                onClick={() => setCurrentListing(listing)}
              >
                <ListingListItemOverlay>
                  <div>{listing.address}</div>
                  <div>
                    {listing.listingPrice}{" "}
                    {Number(listing.listingPrice.replace(/[^0-9.-]+/g, "")) <
                    80000 ? (
                      <span>per month</span>
                    ) : null}
                  </div>
                </ListingListItemOverlay>
                <ListingListItem src={src} key={listing.listingID} />
              </MobileListingListItemContainer>
            );
          })}
        </MobileListingList>
      )}
    </>
  ) : (
    <Loading />
  );
};

export default Featured;
