import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { useWindowSize } from "../hooks";

const igToken =
  "IGQVJXZATBiQ2ViTnd4YmNuc29LMVdhQk5wOFVZANFd6QjZA6S3VEZA3gzT0ZAzeU1TZAmdHb3pWNGoydURNN0UwWUduYjM0WlA2Y3hnUHF6eTkxZA0I1VDRCZAm1MT0EtSmV0N3pmMWMyT0RyT0hHeG1GN0lxdAZDZD";

const token = igToken;

const InstagramFeed = () => {
  const [feeds, setFeeds] = useState([]);
  const { isMobile } = useWindowSize();

  const [isError, setIsError] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const url = `https://graph.instagram.com/me/media?fields=media_count,media_type,permalink,media_url&&access_token=${token}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setIsLoaded(true);
        setFeeds(data.data);
      })
      .catch((e) => {
        console.error("Error:", e);
        setError(e);
      });
  }, []);

  if (isError) {
    return (
      <div className={styles.errorMessage}>
        <p>{JSON.stringify(isError)}</p>
      </div>
    );
  }

  if (!isLoaded) {
    return <div> Loading... </div>;
  }

  return (
    <div
      className={isMobile ? styles.instagramItemsMobile : styles.instagramItems}
    >
      {feeds.slice(0, 24).map((feed, index) => (
        <div key={index} className={styles.instagramItem}>
          <a
            key={index}
            href={feed.permalink}
            className="ig-instagram-link"
            target="_blank"
            rel="noreferrer"
          >
            {feed.media_type === "IMAGE" ||
            feed.media_type === "CAROUSEL_ALBUM" ? (
              <img
                className={styles.instagramImg}
                key={index}
                src={feed.media_url}
                alt="description"
              />
            ) : (
              <video
                className={styles.instagramImg}
                key={index}
                src={feed.media_url}
                type="video/mp4"
              />
            )}

            <div className={styles.instagramIcon}>
              <div className="instagram-count-item">
                <span className="icon">
                  {/*change your svg icon*/}

                  <svg height="18" viewBox="0 0 512 512" width="18">
                    <path
                      fill="currentColor"
                      d="m256 386c-71.683 0-130-58.317-130-130 7.14-172.463 252.886-172.413 260 .001 0 71.682-58.317 129.999-130 129.999zm0-220c-49.626 0-90 40.374-90 90 4.944 119.397 175.074 119.362 180-.001 0-49.625-40.374-89.999-90-89.999zm236 346h-472c-11.046 0-20-8.954-20-20v-472c0-11.046 8.954-20 20-20h472c11.046 0 20 8.954 20 20v472c0 11.046-8.954 20-20 20zm-452-40h432v-432h-432zm372-392c-11.046 0-20 8.954-20 20 0 11.046 8.954 20 20 20 11.046 0 20-8.954 20-20 0-11.046-8.954-20-20-20z"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </a>
        </div>
      ))}
    </div>
  );
};

export default InstagramFeed;
