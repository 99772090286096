const _temp0 = require("./escala_01.webp");
const _temp1 = require("./escala_010..webp");
const _temp2 = require("./escala_02.webp");
const _temp3 = require("./escala_03.webp");
const _temp4 = require("./escala_05.webp");
const _temp5 = require("./escala_06.webp");
const _temp6 = require("./escala_07..webp");
const _temp7 = require("./escala_08..webp");
const _temp8 = require("./escala_082..webp");
const _temp9 = require("./escala_09..webp");
const _temp10 = require("./escala_092..webp");
const _temp11 = require("./main.webp");
module.exports = {
  "escala_01": _temp0,
  "escala_010.": _temp1,
  "escala_02": _temp2,
  "escala_03": _temp3,
  "escala_05": _temp4,
  "escala_06": _temp5,
  "escala_07.": _temp6,
  "escala_08.": _temp7,
  "escala_082.": _temp8,
  "escala_09.": _temp9,
  "escala_092.": _temp10,
  "main": _temp11
}