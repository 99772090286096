const _temp0 = require("./Brix_1.webp");
const _temp1 = require("./Brix_2.webp");
const _temp2 = require("./Brix_3.webp");
const _temp3 = require("./Brix_4.webp");
const _temp4 = require("./Brix_42.webp");
const _temp5 = require("./Brix_5.webp");
const _temp6 = require("./main.webp");
module.exports = {
  "Brix_1": _temp0,
  "Brix_2": _temp1,
  "Brix_3": _temp2,
  "Brix_4": _temp3,
  "Brix_42": _temp4,
  "Brix_5": _temp5,
  "main": _temp6
}