import React, { useState } from "react";
import { Heading, Paragraph, Layer } from "grommet";
import {
  Wrapper,
  ListingImage,
  ListingWrapper,
  ListingData,
  MobileWrapper,
  MobileListingData,
  Back,
} from "./common";

const ListingView = ({ listing, clearListing, isMobile }) => {
  const [modalImage, setModalImage] = useState(null);

  const visibleBlocksMobile = [true, ...listing.images];

  return !isMobile ? (
    <>
      <Back onClick={() => clearListing()}>Back</Back>
      <ListingWrapper background="light-1">
        <Wrapper>
          <ListingData>
            <Heading> {listing.address}</Heading>
            <Paragraph style={{ maxWidth: "80%" }}>
              {listing.remarksConcat}
            </Paragraph>
          </ListingData>
          {listing.images.map((image, ind) => {
            const src = `data:image/png;base64,${image}`;
            return (
              <ListingImage
                key={ind}
                src={src}
                onClick={() => setModalImage(src)}
              />
            );
          })}
          {modalImage ? (
            <Layer
              onEsc={() => setModalImage(null)}
              onClickOutside={() => setModalImage(null)}
            >
              <ListingImage src={modalImage} />
            </Layer>
          ) : null}
        </Wrapper>
      </ListingWrapper>
    </>
  ) : (
    <div>
      <Back
        label={isMobile ? "<" : "Back"}
        isMobile={isMobile}
        onClick={() => clearListing()}
      />
      <MobileWrapper>
        {visibleBlocksMobile.map((image, ind) => {
          console.log(typeof image === "string");
          const src = `data:image/png;base64,${image}`;
          return typeof image === "string" ? (
            <ListingImage
              key={ind}
              src={src}
              onClick={() => setModalImage(src)}
            />
          ) : (
            <MobileListingData>
              <Heading style={{ textAlign: "center" }}>
                {listing.address}
              </Heading>
              <Heading>
                {listing.listingPrice}{" "}
                {Number(listing.listingPrice.replace(/[^0-9.-]+/g, "")) <
                80000 ? (
                  <span>per month</span>
                ) : null}
              </Heading>
              <Paragraph style={{ maxWidth: "80%" }}>
                {listing.remarksConcat}
              </Paragraph>
            </MobileListingData>
          );
        })}
        {modalImage ? (
          <Layer
            onEsc={() => setModalImage(null)}
            onClickOutside={() => setModalImage(null)}
          >
            <ListingImage src={modalImage} />
          </Layer>
        ) : null}
      </MobileWrapper>
    </div>
  );
};

export default ListingView;
