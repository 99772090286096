const _temp0 = require("./filler01.webp");
const _temp1 = require("./filler10.webp");
const _temp2 = require("./filler11.webp");
const _temp3 = require("./filler12.webp");
const _temp4 = require("./filler13.webp");
const _temp5 = require("./filler14.webp");
const _temp6 = require("./filler15.webp");
const _temp7 = require("./filler16.webp");
const _temp8 = require("./filler17.webp");
const _temp9 = require("./filler18.webp");
const _temp10 = require("./filler19.webp");
const _temp11 = require("./filler2.webp");
const _temp12 = require("./filler20.webp");
const _temp13 = require("./filler3.webp");
const _temp14 = require("./filler4.webp");
const _temp15 = require("./filler5.webp");
const _temp16 = require("./filler6.webp");
const _temp17 = require("./filler7.webp");
const _temp18 = require("./filler8.webp");
const _temp19 = require("./filler9.webp");
module.exports = {
  "filler01": _temp0,
  "filler10": _temp1,
  "filler11": _temp2,
  "filler12": _temp3,
  "filler13": _temp4,
  "filler14": _temp5,
  "filler15": _temp6,
  "filler16": _temp7,
  "filler17": _temp8,
  "filler18": _temp9,
  "filler19": _temp10,
  "filler2": _temp11,
  "filler20": _temp12,
  "filler3": _temp13,
  "filler4": _temp14,
  "filler5": _temp15,
  "filler6": _temp16,
  "filler7": _temp17,
  "filler8": _temp18,
  "filler9": _temp19
}