import React, { useState } from "react";
import styled from "styled-components";
import { Layer, Button } from "grommet";
import { CondoImage, CondosWrapper, MobileCondoWrapper } from "../common";
import { InfoBlock, MobileInfoBlock } from "./InfoBlock";

const Back = styled(Button)`
  position: absolute;
  top: ${({ isMobile }) => (isMobile ? "2vh" : "3.5vh")};
  left: 5vw;
  width: ${({ isMobile }) => (isMobile ? "10vw" : "5vw")};
  height: ${({ isMobile }) => (isMobile ? "6%" : null)};
  font-size: ${({ isMobile }) => (isMobile ? "3em" : null)};
  padding-bottom: ${({ isMobile }) => (isMobile ? "10px" : null)};
  border: 1px solid #ddd;
  z-index: 99999;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const CondoView = ({ condo, clearCondo, isMobile }) => {
  const [modalImage, setModalImage] = useState(null);

  const visibleBlocks = [...condo.images];
  const mobileVisibleBlocks = [...condo.images];

  visibleBlocks.splice(4, 0, true);
  mobileVisibleBlocks.splice(0, 0, true);

  return (
    <>
      <Back
        onClick={() => clearCondo()}
        label={isMobile ? "<" : "Back"}
        isMobile={isMobile}
      />
      {!isMobile ? (
        <CondosWrapper>
          {visibleBlocks.map((image, ind) =>
            typeof image === "string" ? (
              <CondoImage
                key={ind}
                src={image}
                onClick={() => setModalImage(image)}
              />
            ) : (
              <InfoBlock condo={condo} />
            )
          )}

          {modalImage ? (
            <Layer
              onEsc={() => setModalImage(null)}
              onClickOutside={() => setModalImage(null)}
            >
              <CondoImage src={modalImage} />
            </Layer>
          ) : null}
        </CondosWrapper>
      ) : (
        <MobileCondoWrapper>
          {mobileVisibleBlocks.map((image, ind) =>
            typeof image === "string" ? (
              <CondoImage
                key={ind}
                src={image}
                onClick={() => setModalImage(image)}
              />
            ) : (
              <MobileInfoBlock condo={condo} />
            )
          )}

          {modalImage ? (
            <Layer
              onEsc={() => setModalImage(null)}
              onClickOutside={() => setModalImage(null)}
            >
              <CondoImage src={modalImage} />
            </Layer>
          ) : null}
        </MobileCondoWrapper>
      )}
    </>
  );
};

export default CondoView;
