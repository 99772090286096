const _temp0 = require("./AvenueOne_2.webp");
const _temp1 = require("./AvenueOne_3.webp");
const _temp2 = require("./AvenueOne_42.webp");
const _temp3 = require("./AvenueOne_5.webp");
const _temp4 = require("./AvenueOne_6.webp");
const _temp5 = require("./main.webp");
module.exports = {
  "AvenueOne_2": _temp0,
  "AvenueOne_3": _temp1,
  "AvenueOne_42": _temp2,
  "AvenueOne_5": _temp3,
  "AvenueOne_6": _temp4,
  "main": _temp5
}