import React from "react";

import styled from "styled-components";

export const StyledBurger = styled.button`
  position: absolute;
  top: 20px;
  right: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 0.4rem;
  height: 4rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  outline: none;

  span {
    width: 2.95rem;
    height: 0.18rem;
    background: black;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
      ${"" /* background-color: red; */}
      height: 0.1875rem;
    }
  }
`;

export const BurgerWrapper = styled.div`
  right: 0;
`;

export const Burger = ({ open, setOpen, ...props }) => {
  const isExpanded = !!open;

  return (
    <StyledBurger
      aria-label="Toggle menu"
      aria-expanded={isExpanded}
      open={open}
      onClick={() => setOpen(!open)}
      {...props}
    >
      <span />
      {!isExpanded ? <span /> : null}
      <span />
    </StyledBurger>
  );
};
