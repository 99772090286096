import React, { useState } from "react";
import styled from "styled-components";
import { Text } from "grommet";
import {
  ListingWrapper,
  CondoOverlay,
  CondoContainer,
  CondoImage,
  CondosWrapper,
  MobileCondosWrapper,
  MobileCondoContainer,
} from "../common";
import { mainText, condos } from "./copy";
import CondoView from "./condo";

const TextBlock = styled(Text)`
  font-family: Roboto;
  font-weight: 400;
  padding: 2em;
`;

const MobileTextBlock = styled(Text)`
  font-family: Roboto;
  font-weight: 300;
  font-size: 1em;
  line-height: 1.4em;
`;

const TextBlockWrapper = styled.div`
  margin: 32px 20%;
  max-width: 40%;
  margin: 0 auto;
  margin-bottom: 64px;
  text-align: center;
`;

const MobileTextBlockWrapper = styled.div`
  margin: 32px 20%;
  max-width: 80%;
  margin: 0 auto;
  margin-bottom: 64px;
  text-align: center;
  border: 4px solid #bd2e2e;
  border-radius: 18px;
  padding: 32px;
`;

const UrbanWrapper = styled(ListingWrapper)`
  overflow: auto;
  border-radius: 0px;
  padding-top: 4em;
  height: 90vh;
  max-width: 100vw;
`;

const MobileUrbanWrapper = styled(ListingWrapper)`
  overflow: auto;
  border-radius: 0px;
  padding-top: 4em;
  height: 75vh;
  max-width: 100vw;
`;

const Urban = ({ isMobile }) => {
  const [currentCondo, setCurrentCondo] = useState(null);
  return currentCondo ? (
    <CondoView
      condo={currentCondo}
      clearCondo={() => setCurrentCondo(null)}
      isMobile={isMobile}
    />
  ) : !isMobile ? (
    <UrbanWrapper>
      <TextBlockWrapper>
        <TextBlock>{mainText}</TextBlock>
      </TextBlockWrapper>
      <CondosWrapper>
        {condos.map((condo) => (
          <CondoContainer
            key={condo.name}
            onClick={() => setCurrentCondo(condo)}
          >
            <CondoOverlay>
              <div>{condo.name}</div>
            </CondoOverlay>
            <CondoImage src={condo.img} />
          </CondoContainer>
        ))}
      </CondosWrapper>
    </UrbanWrapper>
  ) : (
    <MobileUrbanWrapper>
      <MobileTextBlockWrapper>
        <MobileTextBlock>{mainText}</MobileTextBlock>
      </MobileTextBlockWrapper>
      <MobileCondosWrapper>
        {condos.map((condo) => (
          <MobileCondoContainer
            key={condo.name}
            onClick={() => setCurrentCondo(condo)}
          >
            <CondoOverlay>
              <div>{condo.name}</div>
            </CondoOverlay>
            <CondoImage src={condo.img} />
          </MobileCondoContainer>
        ))}
      </MobileCondosWrapper>
    </MobileUrbanWrapper>
  );
};

export default Urban;
