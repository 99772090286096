import React, { useState, useRef } from "react";

import EmailEditor from "react-email-editor";
import styled from "styled-components";
import { Button } from "grommet";
import "./override.css";

const ExportedContainer = styled.div`
  margin: 4em;
`;

const ExportedContent = styled.pre`
  overflow: scroll;
  background-color: #ddd;
  max-height: 75vh;
`;

const EditorContainer = styled.div`
  border: 3px solid #bd2e2e;
  position: relative;
  height: 80vh;
  min-width: 100vw;
`;

const HideBlock = styled.div`
  width: 19.23%;
  height: 50px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  right: 0;
`;

const ButtonContainer = styled.div`
  min-width: 100vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 1em;
`;

const Marketing = () => {
  const emailEditorRef = useRef(null);
  const [exported, setExported] = useState(null);
  const [copied, setCopied] = useState(false);

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      console.log("exportHtml", html);
      setExported(html);
    });
  };

  const onLoad = () => {
    // editor instance is created
    // you can load your template here;
    // const templateJson = {};
    // emailEditorRef.current.editor.loadDesign(templateJson);
  };

  const onReady = () => {};

  const editorStyle = {
    height: "80vh",
    position: "absolute",
    minWidth: "100vw",
  };

  const appearanceOptions = {
    panels: {
      tools: {
        collapsible: false,
      },
    },
  };

  const copyContent = () => {
    const container = document.getElementById("toCopyHtml");
    const containerContent = container.textContent;
    const el = document.createElement("textarea");
    el.value = containerContent;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 4000);
  };

  return (
    <div>
      {!exported ? (
        <ButtonContainer>
          <Button label="Export HTML" onClick={exportHtml} />
        </ButtonContainer>
      ) : null}

      {exported ? (
        <ExportedContainer>
          <ButtonContainer>
            <Button
              label={copied ? "Copied!" : "Copy Content"}
              onClick={() => copyContent()}
            />
            <Button label="Back to Editor" onClick={() => setExported(null)} />
          </ButtonContainer>
          <ExportedContent id="toCopyHtml">{exported}</ExportedContent>
        </ExportedContainer>
      ) : (
        <EditorContainer>
          <EmailEditor
            style={editorStyle}
            ref={emailEditorRef}
            onLoad={onLoad}
            onReady={onReady}
            appearance={appearanceOptions}
          />
          <HideBlock />
        </EditorContainer>
      )}
    </div>
  );
};

export default Marketing;
