import styled from "styled-components";

export const HomeGridWrapper = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.isXL ? "repeat(3, 1fr)" : "repeat(2, 1fr)"};
  grid-template-rows: repeat(2, 1fr);
  overflow: hidden;
  height: 88vh;
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const MobileHomeGridWrapper = styled.div`
  height: 75vh;
  overflow: scroll !important;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(4, 1fr);
  margin-top: 2vh;
  box-shadow: 12px 0px 8px 8px rgba(0, 0, 0, 0.2);
`;
