const _temp0 = require("./Cristalla_1.webp");
const _temp1 = require("./Cristalla_3.webp");
const _temp2 = require("./Cristalla_4.webp");
const _temp3 = require("./Cristalla_5.webp");
const _temp4 = require("./main.webp");
module.exports = {
  "Cristalla_1": _temp0,
  "Cristalla_3": _temp1,
  "Cristalla_4": _temp2,
  "Cristalla_5": _temp3,
  "main": _temp4
}