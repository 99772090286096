import React, { useState, useRef } from "react";
import FocusLock from "react-focus-lock";
import { useOnClickOutside, useWindowSize } from "/src/hooks";
import GridItem from "/src/components/grid-item";
import Menu from "/src/components/menu";
import { Burger, BurgerWrapper } from "/src/components/burger";
import fillerImages from "../../assets/extra/*.webp";
import { HomeGridWrapper, MobileHomeGridWrapper } from "./common";

const FEATURED_IMAGE = require("../../assets/home/featured.webp");
const SOLD_IMAGE = require("/src/assets/home/sold.jpeg");
const URBAN_HOMES_IMAGE = require("/src/assets/home/urban-homes.webp");
const TEAM_IMAGE = require("/src/assets/home/team.webp");

const getFillerImage = () => {
  const sources = Object.values(fillerImages);
  return sources[Math.floor(Math.random() * sources.length)];
};

const gridItems = [
  {
    name: "Featured Listings",
    route: "featured",
    image: FEATURED_IMAGE,
  },
  {
    name: "Sold Portfolio",
    route: "sold",
    image: SOLD_IMAGE,
  },
  {
    name: "Urban Homes",
    route: "urban",
    image: URBAN_HOMES_IMAGE,
  },
  {
    name: "Team",
    route: "team",
    image: TEAM_IMAGE,
  },
];

const fillerImage1 = getFillerImage();
let fillerImage2 = getFillerImage();
if (fillerImage2 === fillerImage1) {
  fillerImage2 = getFillerImage();
}

const fillerItems = [
  { route: "featured", image: fillerImage1 },
  { route: "featured", image: fillerImage2 },
];

const Home = () => {
  const [open, setOpen] = useState(false);
  const { isMobile, isXL } = useWindowSize();
  const node = useRef();
  const menuId = "main-menu";

  useOnClickOutside(node, () => setOpen(false));

  const [a, b, d, e] = gridItems;
  const [c, f] = fillerItems;

  const visibleGridItems = isXL ? [a, b, c, d, e, f] : gridItems;

  return (
    <>
      {isMobile ? (
        <>
          <BurgerWrapper ref={node}>
            <FocusLock disabled={!open}>
              <Burger open={open} setOpen={setOpen} aria-controls={menuId} />

              <Menu open={open} setOpen={setOpen} id={menuId}>
                {gridItems}
              </Menu>
            </FocusLock>
          </BurgerWrapper>
          <MobileHomeGridWrapper>
            {visibleGridItems.map((item, i) => (
              <GridItem
                overlay
                index={i}
                isMobile={isMobile}
                key={item.name}
                to={`${item.route}`}
                src={item.image}
                overlayTitle={item.name}
              />
            ))}
          </MobileHomeGridWrapper>
        </>
      ) : (
        <HomeGridWrapper isXL={isXL}>
          {visibleGridItems.map((item, i) => (
            <GridItem
              overlay
              index={i}
              key={`a-${item.name}`}
              to={`${item.route}`}
              src={item.image}
              overlayTitle={item.name}
            />
          ))}
        </HomeGridWrapper>
      )}
    </>
  );
};

export default Home;
