const _temp0 = require("./main.webp");
const _temp1 = require("./trace_1.webp");
const _temp2 = require("./trace_2.webp");
const _temp3 = require("./trace_4.webp");
const _temp4 = require("./trace_52.webp");
module.exports = {
  "main": _temp0,
  "trace_1": _temp1,
  "trace_2": _temp2,
  "trace_4": _temp3,
  "trace_52": _temp4
}