import React, { useState } from "react";
import { render } from "react-dom";
import { Grommet, Button, Layer } from "grommet";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import styled from "styled-components";
import logoHorizontal from "./assets/logo-horizontal.png";
import Admin from "./admin";
import MarketingEmails from "./admin/marketing-emails";
import Instagram from "./components/instagram";
import { useWindowSize } from "./hooks";
import LeadForm from "./components/lead-form";

Array.prototype.random = function () {
  return this[Math.floor(Math.random() * this.length)];
};

import { Home, Featured, Sold, Urban, Team, ContactUs } from "./pages";

const MainHeader = styled.div`
  height: 10vh;
  width: 100vw;
  background-color: #fff;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: ${({ isMobile, notHome }) =>
    isMobile && !notHome ? "flex-start" : "center"};
  align-items: center;
  padding-left: ${({ isMobile }) => (!isMobile ? "2em" : null)};
`;

const Logo = styled.img`
  width: ${({ isMobile }) => (isMobile ? "60vw" : "350px")};
  margin: ${({ isMobile }) => (isMobile ? "16px" : null)};
  ${"" /* height: ${({ isMobile }) => (isMobile ? "80%" : null)}; */}
  cursor: pointer;
`;

const LeadLayer = styled(Layer)`
  border-radius: 1em;
`;

const LeadButton = styled(Button)`
  right: 5vw;
  position: absolute;
`;

const InstaButton = styled(Button)`
  left: 5vw;
  position: absolute;
`;

const MainWrapper = styled.div`
  overflow-x: hidden;
`;

const globalTheme = {
  global: {
    font: {
      family: "Raleway, sans-serif",
      size: "16px",
      weight: 200,
    },
    colors: {
      focus: "#bd2e2e",
    },
  },
  button: {
    default: {
      font: { weight: "400" },
      background: { color: "#bd2e2e" },
      extend: { textTransform: "uppercase", borderRadius: "10px" },
    },
  },
  chart: {
    color: "#bd2e2e",
  },
};

const App = () => {
  const { isMobile } = useWindowSize();
  const { push, location } = useHistory();
  const [showContactForm, setShowContactForm] = useState(false);

  return (
    <Grommet
      theme={globalTheme}
      style={{
        overflow: "hidden",
        margin: 0,
        maxWidth: "100vw",
        width: "100vw",
      }}
    >
      {showContactForm ? (
        <LeadLayer
          onEsc={() => setShowContactForm(false)}
          onClickOutside={() => setShowContactForm(false)}
        >
          <LeadForm />
        </LeadLayer>
      ) : null}
      <MainHeader isMobile={isMobile} notHome={location.pathname !== "/"}>
        {!isMobile ? (
          <InstaButton label="Instagram" onClick={() => push("/ig-feed")} />
        ) : null}
        <Logo
          isMobile={isMobile}
          src={logoHorizontal}
          onClick={() => push("/")}
        />
        {!isMobile ? (
          <LeadButton
            label="Contact Us"
            onClick={() => setShowContactForm(true)}
          />
        ) : null}
      </MainHeader>

      <Route exact path="/">
        <Home />
      </Route>

      <Route exact path="/featured">
        <Featured isMobile={isMobile} />
      </Route>

      <Route path="/sold">
        <Sold isMobile={isMobile} />
      </Route>

      <Route exact path="/urban">
        <Urban isMobile={isMobile} />
      </Route>

      <Route exact path="/team">
        <Team isMobile={isMobile} />
      </Route>

      {/* admin */}
      <Route exact path="/wip-not-protected-admin">
        <Admin />
      </Route>
      <Route exact path="/admin/marketing">
        <MarketingEmails />
      </Route>

      <Route exact path="/ig-feed">
        <Instagram />
      </Route>

      <Route exact path="/contact-us">
        <ContactUs />
      </Route>

      <MainWrapper />
    </Grommet>
  );
};

render(
  <Router>
    <Switch>
      <App />
    </Switch>
  </Router>,
  document.getElementById("root")
);
