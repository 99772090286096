import React, { useState } from "react";
import { Box, TextInput, TextArea, Button } from "grommet";
import styled from "styled-components";
import { createLead } from "../admin/firebase/actions";

const Wrapper = styled(Box)`
  padding: 2em;
  display: grid;
  grid-row-gap: 2em;
  border: 1px solid #ccc;
  border-radius: 1em;
  max-width: 50vw;
`;

const NameWrapper = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 2em;
`;

const MessageText = styled(TextArea)`
  min-height: 10em;
`;

const SubmitButton = styled(Button)`
  width: 30%;
  justify-self: flex-end;
`;

const LeadForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const submitLead = () => {
    const lead = {
      firstName,
      lastName,
      email,
      subject,
      message,
    };

    createLead(lead);
  };

  return (
    <Wrapper>
      <NameWrapper>
        <TextInput
          placeholder="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextInput
          placeholder="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </NameWrapper>
      <TextInput
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextInput
        placeholder="Subject"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
      />

      <MessageText
        placeholder="Type your message here"
        value={message}
        resize={false}
        onChange={(e) => setMessage(e.target.value)}
      />

      <SubmitButton label="Submit" onClick={submitLead} />
    </Wrapper>
  );
};

export default LeadForm;
