import fifteen21 from "../../assets/condos/1521/main.webp";
import escala from "../../assets/condos/escala/main.webp";
import insignia from "../../assets/condos/insignia/main.webp";
import olive8 from "../../assets/condos/olive8/main.webp";
import nexus from "../../assets/condos/nexus/main.webp";
import luma from "../../assets/condos/luma/main.webp";
import enso from "../../assets/condos/enso/main.webp";
import avenueOne from "../../assets/condos/avenue-one/main.webp";
import brix from "../../assets/condos/brix/main.webp";
import cosmo from "../../assets/condos/cosmo/main.webp";
import cristalla from "../../assets/condos/cristallia/main.webp";
import gallery from "../../assets/condos/gallery/main.webp";
import westlake from "../../assets/condos/2200/main.webp";
import onePacific from "../../assets/condos/one-pacific/main.webp";
import seattleHeights from "../../assets/condos/seattle-heights/main.webp";
import vine from "../../assets/condos/vine/main.webp";
import trace from "../../assets/condos/trace/main.webp";
import veer from "../../assets/condos/veer/main.webp";
import fifteen21Images from "../../assets/condos/1521/*.webp";
import escalaImages from "../../assets/condos/escala/*.webp";
import insigniaImages from "../../assets/condos/insignia/*.webp";
import olive8Images from "../../assets/condos/olive8/*.webp";
import nexusImages from "../../assets/condos/nexus/*.webp";
import lumaImages from "../../assets/condos/luma/*.webp";
import ensoImages from "../../assets/condos/enso/*.webp";
import avenueOneImages from "../../assets/condos/avenue-one/*.webp";
import brixImages from "../../assets/condos/brix/*.webp";
import cosmoImages from "../../assets/condos/cosmo/*.webp";
import cristallaImages from "../../assets/condos/cristallia/*.webp";
import galleryImages from "../../assets/condos/gallery/*.webp";
import westlakeImages from "../../assets/condos/westlake/*.webp";
import onePacificImages from "../../assets/condos/one-pacific/*.webp";
import seattleHeightsImages from "../../assets/condos/seattle-heights/*.webp";
import vineImages from "../../assets/condos/vine/*.webp";
import traceImages from "../../assets/condos/trace/*.webp";
import veerImages from "../../assets/condos/veer/*.webp";

export const mainText = `New high-rise condos in Seattle fly off the market as quickly as they come onto it. For 15 years, Ryan Raffetto has built an extensive network of developers and property managers that supplies reliable listing information oftentimes before posting. If you’re buying or selling a high-rise condo, Raffetto Real Estate can help you get the most from your investment. Save time and allow us to assist you with your search. Complete inquiry form below for property availability at Seattle's newest and upcoming developments.`;

export const condos = [
  {
    name: "FIFTEEN TWENTY-ONE",
    img: fifteen21,
    address: `1521 2nd Avenue, Seattle, WA 98101`,
    info: `Located high above Pike Place Market, 1521 Second Avenue was designed for those whom exceptional life experiences matter. In addition to the building’s exclusive amenity offerings, floor to ceiling windows make each condo feel like a penthouse. The designs for comfort and visual appeal are only rivaled by the view that envelopes each residence.`,
    images: Object.values(fifteen21Images),
  },
  {
    name: "INSIGNIA",
    img: insignia,
    address: "2301 6th Avenue, Seattle, WA 98121",
    info: `The Insignia can be descirbed as edgy and glamourous, yet modern. Located in the heart of downtown Seattle, this luxury condo offers unparalleled views and a collection of architecturally inspired homes that are contempory yet timeless. A 41st floor resident lounge, indoor pool, and fitness studio are just a few of the amenities offered at the Insignia.`,
    images: Object.values(insigniaImages),
  },
  {
    name: "OLIVE 8",
    img: olive8,
    address: "1635 Eighth Avenue, Seattle, WA 98101",
    info: `Located in the Denny Triangle, Olive8 offers the best in downtown living. The first 17 floors of Olive8 building are the Hyatt, which offers residents some attractive features like 24-hour room service and maid service. Above the Hyatt are 22 floors of luxury condominiums that feature Italian oak Pedini cabinets, high end European appliances, and marble surfaces.`,
    images: Object.values(olive8Images),
  },
  {
    name: "NEXUS",
    img: nexus,
    address: "1200 Howell Street, Seattle, WA 98101",
    info: `Nexus is located in the heart of downtown Seattle and close to Capitol Hill, Amazon, REI and South Lake Union. In addition to a host of premium amenities, Nexus offers a rare chance to get in on the ground floor up with a downtown Seattle high rise. Some amenities include a co-working space, fitness center, dog run, rooftop with sky club and much more.`,
    images: Object.values(nexusImages),
  },
  {
    name: "LUMA",
    img: luma,
    address: "1321 Seneca Street, Seattle, WA 98101",
    info: `Luma sits at the crossroads of First Hill and Capitol Hill – two vibrant neighborhoods with distinct personalities and an eclectic mix of galleries, shopping, bars and restaurants. Homes feature high ceilings, custom kitchens, plenty of storage, and expansive light-filled views. Amenities include photographic galleries, pet station, fitness center, rooftop fireplace lounge and more.`,
    images: Object.values(lumaImages),
  },
  {
    name: "ENSO",
    img: enso,
    address: "2201 Westlake Avenue, Seattle, WA 98121",
    info: `Located in the thriving South Lake Union neighborhood, the Enso Condominiums combine downtown living with style and energy efficiency. Enso interiors feature stainless steel appliance, bamboo wood flooring, spa-like bathrooms, granite/marble countertops floor-to-ceiling windows and stunning views. Amenities include a fitness facility, dining and entertainment areas, large roof deck and much more.`,
    images: Object.values(ensoImages),
  },
  {
    name: "AVENUE ONE",
    img: avenueOne,
    address: "2701 First Avenue, Seattle, WA 98121",
    info: `Located in Belltown, the hub of trendy boutiques, popular night clubs and first-class restaurants, Avenue One combines style with the convenience of downtown living. All the units have an open feel, high end modern kitchens and huge windows with views of downtown Seattle. A fitness center, rooftop terrace with BBQ and game room are just a few of the amenities our residents get to enjoy.`,
    images: Object.values(avenueOneImages),
  },
  {
    name: "BRIX",
    img: brix,
    address: "530 Broadway East, Seattle, WA 98102",
    info: `Located in the fast and funky Capitol Hill neighborhood, the Brix condos offer a variety of urban living options within two distinctly different buildings. Both designs have stylish modern features and large windows that flood the units with light. Residents also get to enjoy outdoor amenities such as a central landscaped courtyard, a spacious rooftop deck featuring expansive views and lively street-level retail.`,
    images: Object.values(brixImages),
  },
  {
    name: "THE COSMOPOLITAN",
    img: cosmo,
    address: "819 Virginia St, Seattle, WA 98101",
    info: `The Cosmopolitan combines the excitement of downtown with luxury living. Each home features granite countertops, stainless steel appliances, hardwood floors and deep soaking tubs. Located in the Denny Triangle, The Cosmopolitan is near the Pike Place Market, Convention Center and downtown shopping district. Amenities include a business center, owners lounge, exercise facilities, spa and more.`,
    images: Object.values(cosmoImages),
  },
  {
    name: "CRISTALLA",
    img: cristalla,
    address: "2033 2nd Avenue, Seattle, WA 98104",
    info: `The historically rich Cristalla condos are located in the heart of downtown Seattle, in the Belltown neighborhood. All homes feature hardwood flooring, creative space and most have captivating views. The grand lobby includes an elegant staircase and 2-story water feature. Other amenities include an indoor pool, business center, movie theatre and well-equipped fitness room.`,
    images: Object.values(cristallaImages),
  },
  {
    name: "GALLERY CONDOS",
    img: gallery,
    address: "2911 2nd Avenue, Seattle, WA 98121",
    info: `Just a walk away from the Space Needle, trendy boutiques, popular night clubs and first-class restaurants, the gallery offers the best of downtown living. The Gallery was built with an emphasis on contemporary art. Individual homes feature personalized designs that cater to individual personalities such as the expressionist, minimalist, modernist and individualist.`,
    images: Object.values(galleryImages),
  },
  {
    name: "2200 WESTLAKE",
    img: westlake,
    address: "900 Lenora St, Seattle, WA 98121",
    info: `2200 Westlake condominiums boast three towers: Azur, Aria, and Arte. Located in the Denny Triangle Neighborhood, these condos sit above Whole Foods and next to the Pan Pacific Hotel. Interiors have modern design elements with wide open living spaces and stunning views of the Olympic Mountains, South Lake Union, Downtown Seattle, and Puget Sound.`,
    images: Object.values(westlakeImages),
  },
  {
    name: "ONE PACIFIC TOWER",
    img: onePacific,
    address: "2000 First Avenue, Seattle, WA 98104",
    info: `At One Pacific Tower you will find affluent homes with open floor plans and walls of windows that provide stunning views of Mt Rainier, Elliot Bay and the western edge of the city. Located just a block away from Pike Place, residents enjoy the best of downtown living. In addition to a variety of floor plans, the building offers an indoor pool, hot tub, and gym and more.`,
    images: Object.values(onePacificImages),
  },
  {
    name: "ESCALA SEATTLE",
    img: escala,
    address: "1920 4th Avenue, Seattle, WA 98101",
    info: `Escala provides the quintessential luxurious downtown living experience. Located on the border of Belltown, expect to enjoy all the vibrant retail, restaurants and entertainment venues downtown Seattle has to offer. In addition to divine interiors with up to 10 foot ceilings, this exclusive apartment home features the best collection of amenities in Seattle.`,
    images: Object.values(escalaImages),
  },
  {
    name: "SEATTLE HEIGHTS",
    img: seattleHeights,
    address: "2610 Second Avenue, Seattle, WA 98104",
    info: `Seattle Heights is conveniently located in the heart of Belltown within walking distance to shopping, entertainment, work and play. This upscale urban condo development features a 24/7 front door staff to welcome your visitors. Homes include floor to ceiling windows, marbled imported stone surfaces and tile work throughout.`,
    images: Object.values(seattleHeightsImages),
  },
  {
    name: "THE VINE",
    img: vine,
    address: "2605 Western Avenue, Seattle, WA 98121",
    info: `The Vine is located on Western Avenue, just blocks away from the Olympic Sculpture Park, Seattle Center, and the restaurants and nightlife of Belltown. Many of the homes feature exceptional Space Needle, Elliott Bay and Olympic Mountain views. Amenities include a party room, community view terrace with BBQ, gym, 24 hour concierge staff and more.`,
    images: Object.values(vineImages),
  },
  {
    name: "TRACE",
    img: trace,
    address: "1414 12th Avenue, Seattle, WA 98122",
    info: `Located in Capitol Hill, the Trace Lofts offer the ultimate in urban living. Trendy shops and a funky nightclub scene are right outside the door. Originally built in 1919 this space has been home to a monastery, dance studio and sewing factory. The building was renovated to upscale lofts in 2007 and features homes with floor to ceiling windows, reclaimed Douglas fir flooring, and stunning views.`,
    images: Object.values(traceImages),
  },
  {
    name: "VEER LOFTS",
    img: veer,
    address: "401 Ninth Avenue North, Seattle, WA 98109",
    info: `Located in the up and coming South Lake Union neighborhood, the Veer Lofts combine hip and urban living with energy efficiency. Homes include contemporary design features, large decks, stainless steel appliances, concrete floors, and high ceilings. The building is pet friendly and provides residents with a roof top deck with a BBQ and washing station in the garage.`,
    images: Object.values(veerImages),
  },
];
