import React from "react";
import styled from "styled-components";

const CondoName = styled.div`
  font-size: 2.2em;
  margin-bottom: 32px;
  font-weight: 300;
`;

const MobileCondoName = styled.div`
  font-size: 2.2em;
  margin-bottom: 64px;
  font-weight: 300;
  margin-top: 2vh;
`;

const CondoAddress = styled.div`
  font-size: 1.5em;
  margin-bottom: 16px;
`;

const MobileCondoAddress = styled.div`
  font-size: 1.4em;
  margin-bottom: 32px;
  text-align: center;
`;

const CondoInfo = styled.div`
  font-size: 1em;
  margin: 12px 48px;
  text-align: center;
`;

const MobileCondoInfo = styled.div`
  font-size: 1.2em;
  line-height: 1.6em;
  margin: 12px 28px;
  text-align: center;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2em;
`;

export const InfoBlock = ({ condo }) => (
  <Wrapper>
    <CondoName>{condo.name}</CondoName>
    <CondoAddress>{condo.address}</CondoAddress>
    <CondoInfo>{condo.info}</CondoInfo>
  </Wrapper>
);

export const MobileInfoBlock = ({ condo }) => (
  <Wrapper>
    <MobileCondoName>{condo.name}</MobileCondoName>
    <MobileCondoAddress>{condo.address}</MobileCondoAddress>
    <MobileCondoInfo>{condo.info}</MobileCondoInfo>
  </Wrapper>
);
