const _temp0 = require("./main.webp");
const _temp1 = require("./veer_1.webp");
const _temp2 = require("./veer_2.webp");
const _temp3 = require("./veer_3.webp");
const _temp4 = require("./veer_4.webp");
const _temp5 = require("./veer_5.webp");
const _temp6 = require("./veer_6.webp");
module.exports = {
  "main": _temp0,
  "veer_1": _temp1,
  "veer_2": _temp2,
  "veer_3": _temp3,
  "veer_4": _temp4,
  "veer_5": _temp5,
  "veer_6": _temp6
}