import React, { Fragment } from "react";
import { Heading } from "grommet";
import styled from "styled-components";
import alexBio from "../assets/bio/alex-bio.webp";
import ryanBio from "../assets/bio/ryan-bio.webp";
import rileyBio from "../assets/bio/riley-bio.webp";
import { useWindowSize } from "../hooks";

const TeamWrapper = styled.div`
  display: flex;
  ${"" /* grid-template-columns: repeat(3, 1fr); */}
  justify-content: space-evenly;
  height: 90vh;
  width: 100vw;
  overflow-x: scroll;
  margin-bottom: 200px;
  padding-top: ${({ isMobile }) => (isMobile ? "5vh" : null)};
  flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
`;

const MobileTeamWrapper = styled.div`
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  height: 75vh;
  width: 100vw;
  overflow-x: scroll;
  margin-bottom: 200px;
  margin-top: 3vh;
`;

const Title = styled(Heading)`
  font-family: Roboto;
  font-weight: 300;
  font-size: 4em;
  margin: 0 auto;
  text-align: center;
  margin-top: 5vh;
  position: absolute;
  top: 0;
  left: 5vw;
`;

const TeamMemberWrapper = styled.div`
  width: 400px;
  height: 400px;
  border: 4px solid #bd2e2e;
  border-radius: 8px;
  margin-top: 5vh;
`;

const MobileTeamMemberWrapper = styled.div`
  width: 100%;
  border-radius: 8px;
  margin: 0 auto;
`;

const TeamMemberImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const MobileTeamMemberImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const InfoBlockWrapper = styled.div`
  width: 100%;
  height: 20vh;
`;

const MobileInfoBlockWrapper = styled.div`
  width: 100%;
  margin-bottom: 5vh;
`;

const InfoBlockItem = styled.div`
  width: 100%;
  margin-top: 12px;
  font-weight: ${({ b }) => (b ? "bold" : 300)};
  font-style: ${({ i }) => (i ? "italic" : null)};
  font-size: ${({ size }) => (size ? `${size}em` : "1em")};
  font-family: Roboto;
`;

const MobileInfoBlockItem = styled.div`
  width: 100%;
  margin-top: 1.5em;
  font-weight: ${({ b }) => (b ? "bold" : 300)};
  font-style: ${({ i }) => (i ? "italic" : null)};
  font-size: ${({ size }) => (size ? `${size}em` : "1em")};
  font-family: Roboto;
  text-align: center;
`;

const InfoBlockBio = styled.div`
  margin-top: 42px;
  font-size: ${({ size }) => (size ? `${size}em` : "1em")};
`;

const MobileInfoBlockBio = styled.div`
  margin-top: 2em;
  font-size: ${({ size }) => (size ? `${size}em` : "1em")};
  line-height: 1.6em;
  margin-left: 5vw;
  margin-right: 5vw;
`;

const TeamInfoBlock = ({ member }) => (
  <InfoBlockWrapper>
    <InfoBlockItem b size={1.2}>
      {member.name}
    </InfoBlockItem>
    <InfoBlockItem i>{member.title}</InfoBlockItem>
    <InfoBlockItem>{member.cell}</InfoBlockItem>
    <InfoBlockItem>{member.email}</InfoBlockItem>
    <InfoBlockBio size={1.2}>
      {member.bio.map((section, index) =>
        index === 0 ? (
          <b>{section}</b>
        ) : (
          <InfoBlockItem>{section}</InfoBlockItem>
        )
      )}
    </InfoBlockBio>
  </InfoBlockWrapper>
);

const MobileTeamInfoBlock = ({ member }) => (
  <MobileInfoBlockWrapper>
    <MobileInfoBlockItem b size={2.2}>
      {member.name}
    </MobileInfoBlockItem>
    <MobileInfoBlockItem i size={1.8}>
      {member.title}
    </MobileInfoBlockItem>
    <MobileInfoBlockItem size={1.6}>{member.cell}</MobileInfoBlockItem>
    <MobileInfoBlockItem size={1.6}>{member.email}</MobileInfoBlockItem>
    <MobileInfoBlockBio size={1.2}>
      {member.bio.slice(1, member.bio.length).map((section) => (
        <InfoBlockItem>{section}</InfoBlockItem>
      ))}
    </MobileInfoBlockBio>
  </MobileInfoBlockWrapper>
);

const Team = () => {
  const { isMobile } = useWindowSize();

  const members = [
    {
      name: "Ryan Raffetto",
      img: ryanBio,
      title: "Senior Broker",
      cell: "c _ (206) 261 1206",
      email: "e _Ryan@RaffettoRealEstate.com",
      bio: [
        `Ryan Raffetto`,
        `Ryan earned his reputation as a real estate expert through his encyclopedic knowledge of Seattle’s real estate market, his long-standing relationships with top developers, and his dogged commitment to easing the path for buyers and sellers alike.`,

        `More than 15 years ago, Ryan started off by partnering with downtown developers in sales. His hometown advantage, transparent nature, and enthusiasm for helping others lead him to the real estate matchmaking career that he loves today.`,

        `Extroverted by nature, Ryan cares about people. His passion for building the Seattle community includes on-going support of charitable organizations such as Men’s & Women’s Chorus, Boys & Girls Club of King County, Human Rights Campaign, Seattle Aquarium, Sowing Roots, Gay City, and Fred Hutchinson Cancer Research Center.`,
      ],
    },
    {
      name: "Alex Tiburcio",
      img: alexBio,
      title: "Associate Broker, Team RRE",
      cell: "c_(425) 280 4282",
      email: "e_Alex@pointe3.com",
      bio: [
        `Alex Tiburcio`,
        `A decade of experience in the thriving Seattle housing market; Alexandria Tiburcio began her career with tech-heavy, industry-leading real estate developers while obtaining her Bachelor’s in Psychology and Sociology from Seattle University. Real estate is in her core, a local native that grew up in a family of brokers, investors, mortgage advisors, and sales managers, it was a destiny that manifested naturally.`,

        `A keen understanding of developer, investor, and customer needs with an incredibly organized and savvy research background; Alex listens to her customers and adapts swiftly with advancing changes to the market. Alex helps buyers and sellers successfully achieve their goals with impeccable attention to detail and shrewd negotiation skills, ensuring every customer feels empowered to make the most informed decisions.`,

        `Her experience supporting savvy customers navigate the ever-changing nuances of the complex real estate market is hard to match.`,
      ],
    },
    {
      name: "Riely McCullough",
      img: rileyBio,
      title: "Marketing Assistant",
      bio: [
        `Riely McCullough`,
        `Riely is currently pursuing a Bachelor of Science in Economics at Santa Clara University, and is particularly passionate about the overlap between behavioral economics and consumer-facing technology. Her position at RREA allows her to explore that niche and come up with creative ways to market the Raffetto brand as well as properties, in an industry for which there is no pre-existing formula.`,
      ],
    },
  ];

  return (
    <>
      {!isMobile ? <Title>Our Team</Title> : null}
      {isMobile ? (
        <MobileTeamWrapper>
          {members.map((member) => (
            <div key={member.name}>
              <MobileTeamMemberWrapper>
                <MobileTeamMemberImage src={member.img} />
                <MobileTeamInfoBlock member={member} />
              </MobileTeamMemberWrapper>
            </div>
          ))}
        </MobileTeamWrapper>
      ) : (
        <TeamWrapper>
          {members.map((member) => (
            <TeamMemberWrapper>
              <TeamMemberImage src={member.img} />
              <TeamInfoBlock member={member} />
            </TeamMemberWrapper>
          ))}
        </TeamWrapper>
      )}
    </>
  );
};

export default Team;
