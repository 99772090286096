import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const GridItemWrapper = styled.div`
  background-image: ${(props) => (props.src ? `url(${props.src})` : null)};
  background-size: cover;
  height: ${(props) => (props.isMobile ? `calc(88vh/2)` : `100%`)};
`;

const MobileGridItemWrapper = styled.div`
  background-image: ${(props) => (props.src ? `url(${props.src})` : null)};
  background-size: cover;
  height: ${(props) => (props.isMobile ? `calc(88vh/4)` : `100%`)};
`;

const OverlayTitle = styled.div`
  color: #eee;
  font-size: 2.3em;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const MobileOverlayTitle = styled.div`
  color: #eee;
  font-size: 1.3em;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const OverlayWrapLink = styled(Link)`
  height: 100%;
  background-color: ${({ index, overlay }) =>
    overlay && index && index === 3
      ? `rgba(0, 0, 0, 0.35)`
      : overlay && index !== 3
      ? `rgba(0, 0, 0, 0.6)`
      : "rgba(0, 0, 0, 0)"};
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  letter-spacing: 0.14em;
  text-decoration: none;
  color: black;

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    animation: 0.4s linear;
    transition: background-color 0.4s linear;

    div {
      display: none;
    }
  }
`;

const GridItem = ({
  children,
  overlay,
  overlayTitle,
  index,
  isMobile,
  ...props
}) => {
  const GridContent = (content) => (
    <>
      {isMobile ? (
        <MobileOverlayTitle>{overlayTitle}</MobileOverlayTitle>
      ) : (
        <OverlayTitle id="overlay-title">{overlayTitle}</OverlayTitle>
      )}
      {content}
    </>
  );

  return isMobile ? (
    <MobileGridItemWrapper {...props} isMobile={isMobile}>
      <OverlayWrapLink
        overlay={overlay ? overlay.toString() : null}
        index={index}
        to={props.to}
      >
        {GridContent(children)}
      </OverlayWrapLink>
    </MobileGridItemWrapper>
  ) : (
    <GridItemWrapper {...props} isMobile={isMobile}>
      <OverlayWrapLink
        overlay={overlay ? overlay.toString() : null}
        index={index}
        to={props.to}
      >
        {GridContent(children)}
      </OverlayWrapLink>
    </GridItemWrapper>
  );
};

export default GridItem;
