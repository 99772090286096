{
  "name": "react-email-editor",
  "version": "1.4.0",
  "description": "Unlayer's Email Editor Component for React.js",
  "main": "lib/index.js",
  "module": "es/index.js",
  "files": [
    "css",
    "es",
    "lib",
    "umd"
  ],
  "scripts": {
    "build": "nwb build-react-component",
    "clean": "nwb clean-module && nwb clean-demo",
    "start": "nwb serve-react-demo",
    "test": "nwb test-react",
    "test:coverage": "nwb test-react --coverage",
    "test:watch": "nwb test-react --server",
    "release": "npm run build && npm publish"
  },
  "dependencies": {},
  "peerDependencies": {
    "react": "15.x || 16.x"
  },
  "devDependencies": {
    "nwb": "^0.22.0",
    "react": "^16.13.1",
    "react-dom": "^16.13.1",
    "react-router-dom": "^5.2.0",
    "styled-components": "^4.2.0"
  },
  "author": "",
  "homepage": "https://github.com/unlayer/react-email-editor#readme",
  "license": "MIT",
  "repository": "https://github.com/unlayer/react-email-editor.git",
  "keywords": [
    "react-component"
  ]
}
